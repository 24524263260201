import Model from './interface/Model'

export default class JournalEntryItem extends Model {
  public name: null | string = null

  public number: any = 0

  public description: null | string = null

  public journal_id: any = null

  public account_id: any = null

  public client_id: any = null

  public credits: number = 0

  public debits: number = 0

  public get formCredits() {
    return this.credits
  }

  public set formCredits(value) {
    this.credits = value
    if (this.credits > 0) this.debits = 0
  }

  public get formDebits() {
    return this.debits
  }

  public set formDebits(value) {
    this.debits = value
    if (this.debits > 0) this.credits = 0
  }
}
