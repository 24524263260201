import JournalEntryItem from './JournalEntryItem'
import Model from './interface/Model'

export default class JournalEntry extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'journal' as string | null,
      plural: 'journals' as string | null,
    },
  }

  public id: null | string = null

  public name: null | string = null

  public items: JournalEntryItem[] = []

  public number: any = null

  public created_at: any = null

  public get totalCredits(): number {
    return this.items.reduce((total, item) => total + Number(item.credits), 0)
  }

  public get totalDebits(): number {
    return this.items.reduce((total, item) => total + Number(item.debits), 0)
  }

  public get can_save(): boolean {
    return this.totalCredits === this.totalDebits
  }

  public toObject(source: any) {
    // @ts-ignore
    let instance = this.clone()
    Object.assign(instance, source)
    if (source.items) {
      instance.items = JournalEntryItem.toObjectList(source.items)
    }

    return instance
  }
}
