
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'

import ViewModel from '@/models/ViewModel'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import CreditMemo from '@/models/CreditMemo'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import JournalEntry from '@/models/JournalEntry'
import WebMessage from '@/models/WebMessage'
import Expense from '@/models/Expense'
import moment from 'moment'
import homeTableFields from './home-table-fields'

@Component({
  components: {
    Widget,
    DataTable,
    IconAction,
    SearchInput,
  },
})
export default class JournalEntryHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public fields = homeTableFields

  public query: string[] = []

  // Selected rows
  public selected: string[] = []

  // dataTable field filters
  public fieldFilters: any = {}

  public ready: boolean = false

  public records: number = 0

  public journal_entries: any = []

  public isAllSelected = false

  public query_settings: any = {
    company_fields: [
      {
        name: 'client',
        key: 'client_id',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  @Watch('isAllSelected')
  public onSelectedChange(val: any) {
    if (!val) {
      this.resetSelection()
    } else {
      // @ts-ignore
      this.selected = this.journal_entries.map(i => i.id)
    }
  }

  public get indeterminate(): boolean {
    return this.selected.length > 0 && this.selected.length < this.journal_entries.length
  }

  public mounted() {
    this.loadFilters()
  }

  // Clear all selection
  public resetSelection() {
    this.$set(this, 'selected', [])
  }

  public async rows(context: any): Promise<any> {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    return JournalEntry.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.journal_entries = result.data
      return result.data
    })
  }

  public confirmCreateExpense(row: any) {
    // @ts-ignore
    let total = this.$options.filters.currency(row.item.totalDebits)
    WebMessage.confirm(
      `Are you sure that you want to convert the Accrued "${row.item.name}" (${total})`,
      'Convert Accrued to Bill',
      {
        okTitle: 'Yes, Delete Accrued & Push Bill to Quickbooks',
      },
    ).then((value: boolean) => {
      if (value) {
        this.createExpense(row)
      }
    })
  }

  private createExpense(row: any) {
    this.loading = true
    const debit = row.item.items.find((i: any) => i.debits != 0)

    let expense = Object.assign(new Expense(), {
      created_at: moment(row.item.created_at).format('YYYY-MM-DD'),
      amount: debit.debits,
      category: 'media_cost_ssl',
      commission_deduct: 'media_plans',
      commission_deduct_mode: 'system_defined',
      description: row.item.description,
      name: row.item.name,
      client_id: debit.client_id,
      delete_journal: row.item.id,
    })

    expense.save().then(() => {
      this.refresh(1000)
    })
  }

  public deleteEntry(row: any) {
    row.item.delete().then(() => {
      this.loading = false
      this.refresh(1000)
      WebMessage.success(`Journal ${row.item.name} deleted.`)
    })
  }

  // Refresh dataTable
  public refresh(timeout = 0) {
    this.loading = false
    setTimeout(() => {
      this.dataTable.refresh()
    }, timeout)
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'journal_entry',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('journal_entry').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'journal_entry',
      data: null,
    })
  }
}
