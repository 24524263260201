import DataTableField from '@/components/DataTable/DataTableField'

export default [
  // {
  //   key: 'checkbox',
  //   label: '',
  //   sortable: false,
  //   class: 'text-center align-middle text-uppercase',
  //   show: true,
  //   type: 'checkbox',
  // },
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'number',
    label: 'Number',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'totalDebits',
    label: 'Total Debits',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'totalCredits',
    label: 'Total Credits',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'currency',
  },
  {
    key: 'created_at',
    label: 'Create At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
]
